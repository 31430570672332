export function CustomSelectFilter(){
  let customSelect = document.querySelector(".custom-select");
  let selectBtn = document.querySelector(".select-button");

  let optionsList = document.querySelectorAll(".select-dropdown li");

  // add click event to select button



  if (optionsList && customSelect){
    selectBtn.addEventListener("click", () => {
      // add/remove active class on the container element
      customSelect.classList.toggle("active");
      // update the aria-expanded attribute based on the current state
      selectBtn.setAttribute(
        "aria-expanded",
        selectBtn.getAttribute("aria-expanded") === "true" ? "false" : "true"
      );
    });
  
    optionsList.forEach((option) => {
      function handler(e) {
        // Click Events
        if (e.type === "click" && e.clientX !== 0 && e.clientY !== 0) {
          customSelect.classList.remove("active");
          prestashop.emit('updateFacets', this.children[0].value);
        }
      }
      option.addEventListener("click", handler);
    });
  }
}

