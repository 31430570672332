import { CustomSelectFilter } from "./testfunction";
window.addEventListener("load", searchButtonEventListener());

function displaySearchBar(){
  var searchContainer = document.getElementById('header-search')
  console.log(searchContainer.classList.value)
  var isHidden = false;
  for (let i = 0; i < searchContainer.classList.length; i++){
    if (searchContainer.classList[i] == 'hide'){
      var isHidden = true
    }
  }
  if (isHidden){
    searchContainer.classList.remove('hide')
    searchContainer.classList.add('show')
  }
  else {
    searchContainer.classList.remove('show')
    searchContainer.classList.add('hide')
  }
};

function displayAccountBox(){
  var accountBox = document.getElementById('account-box')
  var isHidden = false;
  // check is hidden startly to prevent animation at load.
  if (accountBox.classList.contains('hidden-start')){
    accountBox.classList.remove('hidden-start')
    isHidden = true;
  }
  // check is animated to set display none ( when is hidden )
  if (accountBox.classList.contains('animated')){
    accountBox.classList.remove('animated')
  }
  // check if is hidden, show
  if (accountBox.classList.contains('hidden') || isHidden){
    accountBox.classList.remove('hidden')
    accountBox.classList.add('show')
    document.addEventListener('click', hideAccountBox);
  }
  // if is showed, hide
  else {
    accountBox.classList.remove('show')
    accountBox.classList.add('hidden')
    document.removeEventListener('click', hideAccountBox);
  }
}

function hideAccountBox(event) {
  let accountBox = document.getElementById('account-box')
  let accountButton = document.getElementById('u-link-body');
  if(accountBox.classList.contains('show')){
    if (accountBox.classList.contains('animated')){
      accountBox.classList.remove('animated')
    }
    if (!accountButton.contains(event.target) && !accountBox.contains(event.target)) {
      accountBox.classList.remove('show');
      accountBox.classList.add('hidden')
      document.removeEventListener('click', hideAccountBox);
    }
  }
}

function searchButtonEventListener(){
  console.log('zresetowałem sie')
  let searchButton = document.getElementById('search-button-header');
  let accountButton = document.getElementById('u-link-body');
  if (accountButton){
    accountButton.addEventListener(
      'click',
      function(){
        displayAccountBox();
      }
    );
    let accountBox = document.getElementById('account-box')
    accountBox.addEventListener('animationend', function() {
      accountBox.classList.add('animated')
    })
  }
  
  searchButton.addEventListener(
    'click',
    function() {
      displaySearchBar();
    }
  );
  CustomSelectFilter();

}



